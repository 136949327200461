<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
export default {
  title: 'About Kamakhya Nagar Development Committee',
  metaInfo: {
    title: 'About Kamakhya Nagar Development Committee'
  },
}
</script>
<style scoped>
.about {
  padding-top: 64px !important;
  min-height: 51vh;
}
</style>
